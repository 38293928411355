import {
  ActionIcon,
  Anchor,
  Avatar,
  Box,
  Button,
  Divider,
  Group,
  Header,
  Menu,
  Transition,
  UnstyledButton,
} from "@mantine/core";
import { useTimeout } from "@mantine/hooks";
import {
  IconChevronDown,
  IconChevronLeft,
  IconChevronsUp,
  IconCoin,
  IconCurrencyDollar,
  IconDeviceMobile,
  IconLogout,
  IconMoon,
  IconQuestionMark,
  IconSettings,
  IconSun,
} from "@tabler/icons-react";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { isAndroid } from "react-device-detect";
import { useBoard } from "../../common/boards/board.hooks";
import { useAppStore } from "../../common/stores/app.store";
// import { useRequireAuth } from "../common/auth/useRequireAuth"
import { useAuthStore } from "../../common/stores/auth.store";
import { getInitials, PLAY_STORE_LINK } from "../../common/util";
import BoardLink from "../boards/BoardLink";
import Hider from "../common/Hider";
import NotificationsButton from "../notifications/NotificationsButton";
import Logo from "./Logo";
// const FRESHDESK_URL = "https://scoreboarder.freshdesk.com/support/home";
const HeaderComp = () => {
  const { t } = useTranslation(["common"]);
  // const toggleOpen = useAppStore((state) => state.toggleOpen);
  const user = useAuthStore((state) => state.user);
  const token = useAuthStore((state) => state.token);
  // console.log("user :", user);
  const { board } = useBoard();
  const logout = useAuthStore((state) => state.logout);
  const router = useRouter();
  const isLogin =
    router.pathname.indexOf("/login") === 0 ||
    router.pathname.indexOf("/pwa-home") === 0;
  const isBoard = router.pathname.indexOf("/boards") === 0;
  // console.log("isBoard :", isBoard);
  const toggleDark = useAppStore((s) => s.toggleDark);
  const dark = useAppStore((s) => s.dark);
  const [displayDark, setDisplayDark] = useState(false);
  useEffect(() => setDisplayDark(dark), [dark]);
  const [userChecked, setUserChecked] = useState(false);
  useTimeout(() => setUserChecked(true), 1000, {
    autoInvoke: true,
  });
  useEffect(() => {
    if (token) setUserChecked(true);
  }, [token]);
  const doLogout = () => {
    logout();
    router.push(isPwa ? "pwa-home" : "/");
  };
  const isPwa = useAppStore((s) => s.isPwa);
  const showBack =
    router.asPath !== "/" &&
    router.asPath !== "/dashboard" &&
    router.asPath !== "/pwa-home";
  const noLinksWidth = isBoard ? "sm" : 350;
  const deep =
    router.asPath !== "/" &&
    router.asPath !== "/dashboard" &&
    router.asPath !== "/pwa-home";
  // console.log("router.asPath :", router.asPath);
  const registerLink = deep
    ? `/login?state=register&redirect=${encodeURIComponent(router.asPath)}`
    : `/login?state=register`;
  const loginLink = deep
    ? `/login?redirect=${encodeURIComponent(router.asPath)}`
    : `/login?`;
  // const open = useAppStore((state) => state.open);
  // const theme = useMantineTheme();
  if (router.asPath === "/pwa-home") return null;
  return (
    <Header height={70} p="md">
      <Group sx={{ height: "100%" }} px={20} position="apart" noWrap>
        <Group pt={6} noWrap>
          <Transition transition="slide-right" mounted={showBack}>
            {(transitionStyles) => (
              <ActionIcon
                style={transitionStyles}
                onClick={() => router.back()}
              >
                <IconChevronLeft />
              </ActionIcon>
            )}
          </Transition>
          <Logo />
          <Group pt={2}>{board && <BoardLink board={board} />}</Group>
        </Group>
        <Transition
          mounted={userChecked}
          transition="fade"
          duration={400}
          timingFunction="ease"
        >
          {(styles) => (
            <Group style={styles} noWrap>
              {!token && !isLogin && (
                <>
                  <Hider smallerThan={noLinksWidth}>
                    <Group>
                      <Anchor size="sm" component={Link} href="/pricing">
                        {t("pricing")}
                      </Anchor>
                    </Group>
                  </Hider>
                  <Hider smallerThan={noLinksWidth}>
                    <Group>
                      <Anchor size="sm" component={Link} href="/support">
                        {t("support")}
                      </Anchor>
                    </Group>
                  </Hider>
                  <Button size="xs" component={Link} href={registerLink}>
                    {t("signUp")}
                  </Button>
                  <Hider smallerThan="xs">
                    <Box>
                      <Anchor size="sm" component={Link} href={loginLink}>
                        {t("orLogin")}
                      </Anchor>
                    </Box>
                  </Hider>
                </>
              )}
              {user && <NotificationsButton />}
              {user && (
                <Menu
                  width={260}
                  position="bottom-end"
                  transitionProps={{ transition: "pop-top-right" }}
                >
                  <Menu.Target>
                    <UnstyledButton className="user-menu">
                      <Group spacing={7}>
                        <Avatar src={user.image} alt={user.name} size={20}>
                          {getInitials(user.name)}
                        </Avatar>
                        <IconChevronDown size={12} stroke={1.5} />
                      </Group>
                    </UnstyledButton>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item>{user.name}</Menu.Item>

                    <Menu.Item
                      icon={<IconSettings size={14} stroke={1.5} />}
                      component={Link}
                      href="/settings"
                    >
                      {t("accountSettings")}
                    </Menu.Item>

                    {user.admin && (
                      <Menu.Item
                        component={Link}
                        href="/admin"
                        icon={<IconChevronsUp size={14} stroke={1.5} />}
                      >
                        {t("admin")}
                      </Menu.Item>
                    )}

                    <Menu.Item
                      component={Link}
                      href="/billing"
                      icon={<IconCurrencyDollar size={14} stroke={1.5} />}
                    >
                      {t("plans")}
                    </Menu.Item>
                    <Menu.Item
                      component={Link}
                      href="/payments"
                      icon={<IconCoin size={14} stroke={1.5} />}
                    >
                      {t("myPayments")}
                    </Menu.Item>

                    {!isPwa && (
                      <Menu.Item
                        component={Link}
                        href={isAndroid ? PLAY_STORE_LINK : "/app"}
                        target={isAndroid ? "_blank" : "_self"}
                        icon={<IconDeviceMobile size={14} stroke={1.5} />}
                      >
                        {t("installApp")}
                      </Menu.Item>
                    )}
                    <Menu.Item
                      component={Link}
                      href="/support"
                      icon={<IconQuestionMark size={14} stroke={1.5} />}
                    >
                      {t("support")}
                    </Menu.Item>
                    <Divider mt={5} mb={20} />
                    <Menu.Item
                      className="logout"
                      onClick={doLogout}
                      icon={<IconLogout size={14} stroke={1.5} />}
                    >
                      {t("logout")}
                    </Menu.Item>
                    <Menu.Item
                      aria-label="dark mode"
                      onClick={toggleDark}
                      icon={
                        displayDark ? (
                          <IconSun size={14} stroke={1.5} />
                        ) : (
                          <IconMoon size={14} stroke={1.5} />
                        )
                      }
                    ></Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              )}
            </Group>
          )}
        </Transition>
      </Group>
    </Header>
  );
};
export default HeaderComp;
