import { Navbar, Stack } from "@mantine/core";
import { useRouter } from "next/router";
import Hider from "../common/Hider";
import { AdminLinks } from "./AdminLinks";
import { MainLinks } from "./MainLinks";

const NavbarComp = () => {
  // const open = useAppStore((state) => state.open);
  // const user = useAuthStore((state) => state.user);
  const router = useRouter();
  // const logout = useAuthStore((state) => state.logout);
  // const { t } = useTranslation(["navbar", "common"]);
  const showBoardLinks = router.asPath.indexOf("/boards") === 0;
  const showAdminLinks = router.asPath.indexOf("/admin") === 0;
  const showBar = showBoardLinks || showAdminLinks;

  // let { boardShortLink, boardSlug } = router.query;
  // console.log("boardShortLink :", boardShortLink);
  // const { data } = useBoard(boardShortLink);
  return (
    <>
      {showBar && (
        <Hider smallerThan="xs">
          <Navbar width={{ base: 70, md: 160 }} p="xs">
            <Navbar.Section grow mt={0}>
              <Stack justify="center" spacing={15} pl={0}>
                {showBoardLinks && <MainLinks />}
                {showAdminLinks && <AdminLinks />}
              </Stack>
            </Navbar.Section>
          </Navbar>
        </Hider>
      )}
    </>
  );
};
export default NavbarComp;
