import { Board } from "../boards/board.schema";
import { Match } from "../matches/match.schema";
import { Member } from "../members/member.schema";
import { TokenType } from "../payments/tokentype.schema";
import { Record } from "../record.schema";
import { Weapon } from "../sports";

/* eslint-disable unused-imports/no-unused-vars */

export enum TournamentType {
  ELIMINATION = "ELIMINATION",
  POOLS = "POOLS",
  POOLS_ELIMINATION = "POOLS_ELIMINATION",
  TEAM = "TEAM",
  TEAM_POOLS_ELIMINATION_SPLIT = "TEAM_POOLS_ELIMINATION_SPLIT",
  ENDLESS_POOL = "ENDLESS_POOL",
  TEAM_POOLS_ELIMINATION = "TEAM_POOLS_ELIMINATION",
}
export enum EventType {
  TOURNAMENT = "TOURNAMENT",
  CLASS = "CLASS",
}
export enum SeedType {
  SCORE = "SCORE",
  RANDOM = "RANDOM",
  USFA = "USFA",
  MANUAL = "MANUAL",
}
export enum RecurringType {
  NONE = "NONE",
  DAILY = "DAILY",
  WEEKDAY = "WEEKDAY",
  NTH_WEEKDAY_OF_MONTH = "NTH_WEEKDAY_OF_MONTH",
  ANNUALLY = "ANNUALLY",
  CUSTOM = "CUSTOM",
}
export enum RecurringUnit {
  MINUTE = "MINUTE",
  DAY = "DAY",
  WEEK = "WEEK",
  MONTH = "MONTH",
}
export const MAX_MEMBERS = 128;
/* eslint-enable unused-imports/no-unused-vars */
export interface PoolMember {
  member: string;
  wins?: number;
  losses?: number;
  for?: number;
  against?: number;
  diff?: number;
  place?: number;
  withdrawn?: boolean;
}
export interface Pool {
  _id: string;
  started?: boolean;
  startDate?: Date;
  members: PoolMember[];
  matches?: string[];
  complete?: boolean;
  completeDate?: Date;
}
export interface EliminationMatchup {
  members: string[];
  winner?: string;
  bye?: boolean;
  complete?: boolean;
  match?: string;
  withdrawal?: boolean;
  playoff?: boolean;
}
export interface Elimination {
  round: number;
  size: number;
  started: boolean;
  startDate: Date;
  seeds: string[];
  winners: string[];
  losers: string[];
  matchups: EliminationMatchup[];
  matches: string[];
  complete: boolean;
  completeDate: Date;
}
export interface Registration {
  usfaRating?: string;
  score?: number;
  order: number;
  _id: string;
  user?: string;
  member?: string;
  country?: string;
  club?: string;
  paid?: boolean;
  approved?: boolean;
  checkedIn?: boolean;
}
export const MIN_MEMBERS = 2;
export const DEFAULT_POOL_SIZE = 7;
export const DEFAULT_POOL_POINTS = 5;
export const DEFAULT_ELIMINATION_POINTS = 15;
export const MAX_REGISTRATION_PRICE = 200;
export interface Event extends Record {
  _id: string;
  name: string;
  creator: string;
  board: string | Board;
  type: TournamentType;
  eventType: EventType;
  seedType: SeedType;
  members: string[] | Member[];
  teams: string[] | Member[];
  referees: string[];
  refereeInvites: string[];
  matches: string[] | Match[];
  seeds: string[];
  eliminationSeeds: string[];
  results: string[];
  pools: Pool[];
  eliminations: Elimination[];
  poolSize: number;
  poolPoints: number;
  eliminationRounds: number;
  eliminationPoints: number;
  poolsAffectScore: boolean;
  requirePoolPoints: boolean;
  eliminationAffectScore: boolean;
  started: boolean;
  startDate: Date;
  poolsComplete: boolean;
  poolsCompleteDate: Date;
  complete: boolean;
  completeDate: Date;
  shortLink: string;
  image: string;
  imageKey: string;
  imageUpdatedAt: Date;
  archived: boolean;
  withdrawals: string[];
  playoff: boolean;
  scheduledDate?: Date | string;
  recurringType: RecurringType;
  openRegistration: boolean;
  requireCheckIn: boolean;
  autoApproveRegistrations: boolean;
  requireRegistrationPayment: boolean;
  registrationPrice: number;
  registrations: Registration[];
  usfaEventCode?: string;
  usfaEventRating?: string;
  weapon?: Weapon;
  maxMembers?: number;
  requireFullName: boolean;
  requireUsfaMemberNumber: boolean;
  screenshot: string;
  screenshotKey: string;
  screenshotUpdatedAt: Date;
  scheduleProcessed: boolean;
  tokenType: string | TokenType;
}
