// log specific events happening.
import { AnalyticsBrowser } from "@segment/analytics-next";
import { useAuthStore } from "./stores/auth.store";
let _a: AnalyticsBrowser;
const getAnalytics = (): AnalyticsBrowser => {
  if (_a) return _a;
  _a = AnalyticsBrowser.load({
    writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY || "",
  });
  return _a;
};
export const trackPage = () => {
  // console.log("trackPage :");
  window.gtag &&
    window.gtag("event", "page_view", {
      page_location: window.location.href,
      page_path: window.location.pathname,
      send_to: process.env.NEXT_PUBLIC_GA4_ID,
    });
  const token = useAuthStore.getState().token;
  // console.log("token :", token);
  if (token) getAnalytics()?.page();
};

export const trackEvent = (action: string, params?: any) => {
  window.gtag && window.gtag("event", action, params);
  // const dataLayer = (window as any).dataLayer as any[] | undefined;
  // dataLayer && dataLayer.push({ event: action, ...params });
  const token = useAuthStore.getState().token;
  if (token) getAnalytics()?.track(action, params);
};

export const identify = (traits?: any) => {
  const token = useAuthStore.getState().token;
  const userId = useAuthStore.getState().user?._id;
  // const dataLayer = (window as any).dataLayer as any[] | undefined;
  // dataLayer && dataLayer.push({ userId: id, ...traits });
  if (token && userId) getAnalytics()?.identify(userId, traits);
};
