export enum Sport {
  FENCING = "fencing",
  TENNIS = "tennis",
  PADEL = "padel",
  SQUASH = "squash",
  TABLE_TENNIS = "tableTennis",
  PICKLEBALL = "pickleball",
  BADMINTON = "badminton",
  HEMA = "hema",
  OTHER = "other",
  TAEKWONDO = "taekwondo",
  JIU_JITSU = "jiuJitsu",
  MUAY_THAI = "muayThai",
  JUDO = "judo",
  MMA = "MMA",
}
export enum Weapon {
  MIXED = "mixed",
  FOIL = "foil",
  EPEE = "epee",
  SABRE = "sabre",
  HEMA_SABRE = "hemaSabre",
  HEMA_RAPIER = "hemaRapier",
  HEMA_LONGSWORD = "hemaLongsword",
  HEMA_RAPIER_DAGGER = "hemaRapierDagger",
  HEMA_SWORD_BUCKLER = "hemaSwordBuckler",
}
const sports = [
  {
    sport: Sport.FENCING,
    limitedPoints: true,
    weapons: [Weapon.EPEE, Weapon.FOIL, Weapon.SABRE],
    defaultMatchPoints: 15,
    defaultPoolPoints: 5,
    defaultEliminationPoints: 15,
    defaultTeamPoints: 45,
    individual: true,
    teams: true,
    teamMinMembers: 3,
    teamMaxMembers: 4,
  },
  {
    sport: Sport.HEMA,
    limitedPoints: true,
    weapons: [
      Weapon.HEMA_RAPIER,
      Weapon.HEMA_LONGSWORD,
      Weapon.HEMA_SABRE,
      Weapon.HEMA_RAPIER_DAGGER,
      Weapon.HEMA_SWORD_BUCKLER,
    ],
    defaultMatchPoints: 15,
    defaultPoolPoints: 5,
    defaultEliminationPoints: 15,
    defaultTeamPoints: 45,
    individual: true,
    teams: true,
    teamMinMembers: 3,
    teamMaxMembers: 4,
  },
  {
    sport: Sport.BADMINTON,
    limitedPoints: true,
    defaultMatchPoints: 2,
    defaultPoolPoints: 2,
    defaultTeamPoints: 2,
    defaultEliminationPoints: 2,
    individual: true,
    teams: true,
    teamMinMembers: 2,
    teamMaxMembers: 2,
  },
  {
    sport: Sport.TENNIS,
    limitedPoints: true,
    defaultMatchPoints: 3,
    defaultPoolPoints: 3,
    defaultEliminationPoints: 3,
    defaultTeamPoints: 2,
    individual: true,
    teams: true,
    teamMinMembers: 2,
    teamMaxMembers: 2,
  },
  {
    sport: Sport.PICKLEBALL,
    limitedPoints: true,
    defaultMatchPoints: 2,
    defaultPoolPoints: 2,
    defaultTeamPoints: 2,
    defaultEliminationPoints: 2,
    individual: true,
    teams: true,
    teamMinMembers: 2,
    teamMaxMembers: 2,
  },
  {
    sport: Sport.SQUASH,
    limitedPoints: true,
    defaultMatchPoints: 3,
    defaultPoolPoints: 3,
    defaultTeamPoints: 3,
    defaultEliminationPoints: 3,
    individual: true,
    teams: true,
    teamMinMembers: 2,
    teamMaxMembers: 2,
  },
  {
    sport: Sport.TABLE_TENNIS,
    limitedPoints: true,
    defaultMatchPoints: 3,
    defaultPoolPoints: 3,
    defaultTeamPoints: 3,
    defaultEliminationPoints: 3,
    individual: true,
    teams: true,
    teamMinMembers: 2,
    teamMaxMembers: 2,
  },
  {
    sport: Sport.JUDO,
    individual: true,
    teams: false,
    limitedPoints: false,
  },
  {
    sport: Sport.TAEKWONDO,
    individual: true,
    teams: false,
    limitedPoints: false,
  },
  {
    sport: Sport.MMA,
    individual: true,
    teams: false,
    limitedPoints: false,
  },
  {
    sport: Sport.JIU_JITSU,
    individual: true,
    teams: false,
    limitedPoints: false,
  },
  {
    sport: Sport.MUAY_THAI,
    individual: true,
    teams: false,
    limitedPoints: false,
  },
  // teams only sports
  //   {
  //     sport: Sport.PADEL,
  //     defaultMatchPoints: 2,
  //     defaultPoolPoints: 2,
  //     defaultTeamPoints: 2,
  //     defaultEliminationPoints: 2,
  //     individual: false,
  //     teams: true,
  //   },
];
export default sports;
