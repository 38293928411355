import { Center, Loader, Text } from "@mantine/core";
import { useTranslation } from "next-i18next";

const Loading = () => {
  const { t } = useTranslation();
  return (
    <Center>
      <Text>{t("loading")}&nbsp;</Text>
      <Loader size="xs" />
    </Center>
  );
};
export default Loading;
