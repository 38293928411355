import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { updateUser } from "../../common/api";
import { useAppStore } from "../../common/stores/app.store";
import { useAuthStore } from "../../common/stores/auth.store";
const base64ToUint8Array = (base64: string) => {
  const padding = "=".repeat((4 - (base64.length % 4)) % 4);
  const b64 = (base64 + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(b64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};
const PushManager = () => {
  const pushPermission = useAppStore((s) => s.pushPermission);
  //   console.log("pushPermission :", pushPermission);
  const setPushPermission = useAppStore((s) => s.setPushPermission);
  // console.log('token :', token);
  const user = useAuthStore((state) => state.user);
  const [subscription, setSubscription] = useState<PushSubscription | null>(
    null
  );
  const pushSupported =
    typeof window !== "undefined" &&
    // (window as any).workbox !== undefined
    navigator.serviceWorker &&
    window.PushManager &&
    window.Notification;
  const [registration, setRegistration] =
    useState<ServiceWorkerRegistration | null>(null);
  const { mutate: doUpdateUser } = useMutation(updateUser, {});
  useEffect(() => {
    // console.log("checking sw");
    // console.log("navigator :", navigator);
    // console.log("window.workbox :", (window as any).workbox);
    if (pushSupported) {
      setPushPermission(Notification.permission);
      // run only in browser
      // console.log("waiting for reg");
      navigator.serviceWorker.ready.then((reg) => {
        // console.log("reg :", reg);
        setRegistration(reg);
        // reg.pushManager.getSubscription().then((sub) => {
        //   console.log("sub :", sub);
        //   if (
        //     sub &&
        //     !(
        //       (sub as any).expirationTime &&
        //       Date.now() > (sub as any).expirationTime - 5 * 60 * 1000
        //     )
        //   ) {
        //     setSubscription(sub);
        //   }
        // });
        // reg.pushManager.subscribe();
      });
    }
  }, [pushSupported, setPushPermission]);
  useEffect(() => {
    if (!subscription || !user) {
      return;
    }
    // console.log("subscription :", subscription);
    const subJson = subscription.toJSON();
    // console.log("subJson :", subJson);

    if (pushPermission !== "granted") {
      // Notification.requestPermission().then((permission) => {
      //   // console.log("permission :", permission);
      //   if (permission === "granted") setPushPermission(permission);
      // });
      return;
    }
    if (user.pushEndpoint === subJson.endpoint) {
      // console.log("already stored push endpoint");
      return;
    }
    // console.log("updating user");
    doUpdateUser({
      _id: user._id,
      pushEndpoint: subJson.endpoint,
      pushKeysAuth: subJson.keys?.auth,
      pushKeysP256dh: subJson.keys?.p256dh,
    });
  }, [doUpdateUser, subscription, user, pushPermission, setPushPermission]);
  useEffect(() => {
    // console.log("registration :", registration);
    if (!registration || !user) return;
    registration.pushManager
      .subscribe({
        userVisibleOnly: true,
        applicationServerKey: base64ToUint8Array(
          process.env.NEXT_PUBLIC_WEB_PUSH_PUBLIC_KEY || ""
        ),
      })
      .then((sub) => {
        setSubscription(sub);
        // console.log("web push subscribed!");
        // console.log(sub);
      });
    // TODO: you should call your API to save subscription data on server in order to send web push notification from server
  }, [registration, user]);

  // useEffect(() => {
  //   if (!user) return;
  //   console.log("registration :", registration);
  //   if (!registration) return;
  //   registration.pushManager
  //     .subscribe({
  //       userVisibleOnly: true,
  //       applicationServerKey: base64ToUint8Array(
  //         process.env.NEXT_PUBLIC_WEB_PUSH_PUBLIC_KEY || ""
  //       ),
  //     })
  //     .then((sub) => {
  //       setSubscription(sub);
  //       // console.log("web push subscribed!");
  //       // console.log(sub);
  //     });
  // }, [registration, user]);
  return <></>;
};
export default PushManager;
