import create from "zustand";
import { User } from "../users/user.schema";
import { persist } from "zustand/middleware";
export const AUTH_STORE = "auth-store";
export interface AuthState {
  logout: () => void;
  token?: string | null;
  setToken: (token: string) => void;
  user?: User | null;
  setUser: (user: User) => void;
}
export const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      token: null,
      setToken: (token: string | null) => set(() => ({ token })),
      user: null,
      setUser: (user: User | null) => set(() => ({ user })),
      logout: () => {
        set(() => ({ token: null, user: null }));
      },
    }),
    {
      name: AUTH_STORE,
      partialize: (state) => ({ token: state.token }),
    }
  )
);
