import {
  Anchor,
  Avatar,
  Group,
  MediaQuery,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useBoard } from "../../common/boards/board.hooks";
import { getInitials } from "../../common/util";
import { useAuthStore } from "../../common/stores/auth.store";
const Logo = () => {
  // const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const token = useAuthStore((state) => state.token);
  const [root, setRoot] = useState("/");
  const router = useRouter();
  const { board } = useBoard();
  const isBoard = router.asPath.indexOf("/boards/") === 0;
  // console.log("isBoard :", isBoard);
  useEffect(() => {
    setRoot(token ? "/dashboard" : "/");
  }, [token]);
  let image = "/assets/icons/ios/64_dark.png";
  if (theme.colorScheme === "dark") image = "/assets/icons/ios/64.png";
  if (isBoard && board?.image) image = board.image;
  return (
    <Anchor
      href={root}
      aria-label="home"
      component={Link}
      sx={(theme) => ({
        hover: {
          textDecoration: "none",
        },
        color: theme.colorScheme === "dark" ? "#fff" : "#000",
        // paddingTop: 4,
      })}
    >
      <Group spacing={4} noWrap>
        <Avatar
          src={image}
          size={30}
          alt={isBoard && board ? board.name : t("scoreboarder")}
        >
          {isBoard && board?.name ? getInitials(board.name) : ""}
        </Avatar>
        {!isBoard && (
          <MediaQuery smallerThan="xs" styles={{ display: "none" }}>
            <Text
              sx={() => ({
                backgroundImage:
                  theme.colorScheme === "dark"
                    ? "url('/assets/images/widelogo.png')"
                    : "url('/assets/images/widelogo-dark.png')",
                backgroundRepeat: "no-repeat",
                padding: 0,
                height: 32,
                width: 167,
                textOverflow: "hidden",
                textIndent: -9999,
                paddingTop: 8,
                backgroundSize: 155,
                backgroundPosition: "10px 8px",
              })}
            >
              {t("scoreboarder")}
            </Text>
          </MediaQuery>
        )}
      </Group>
    </Anchor>
  );
};
export default Logo;
