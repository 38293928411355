import { Board } from "../boards/board.schema";
import { Match } from "../matches/match.schema";
import { Member } from "../members/member.schema";
import { Record } from "../record.schema";
import { Event } from "../events/event.schema";
import { User } from "../users/user.schema";
export const START_ELO_SCORE = 500;
export enum NotificationType {
  WELCOME = "WELCOME",
  BOARD_ADMIN_INVITE = "BOARD_ADMIN_INVITE",
  BOARD_ADMIN_ADDED = "BOARD_ADMIN_ADDED",
  TOURNAMENT_REFEREE_INVITE = "TOURNAMENT_REFEREE_INVITE",
  TOURNAMENT_REFEREE_ADDED = "TOURNAMENT_REFEREE_ADDED",
  BOARD_MEMBER_INVITE = "BOARD_MEMBER_INVITE",
  BOARD_USER_INVITE = "BOARD_USER_INVITE",
  MATCH_ADDED = "MATCH_ADDED",
  MATCH_APPROVAL_REQUIRED = "MATCH_APPROVAL_REQUIRED",
  MATCH_APPROVED = "MATCH_APPROVED",
  BOARD_REFEREE_ADDED = "BOARD_REFEREE_ADDED",
  BOARD_REFEREE_INVITE = "BOARD_REFEREE_INVITE",
  TOURNAMENT_REGISTRATION_PENDING = "TOURNAMENT_REGISTRATION_PENDING",
  TOURNAMENT_REGISTRATION = "TOURNAMENT_REGISTRATION",
  TOURNAMENT_REGISTRATION_APPROVED = "TOURNAMENT_REGISTRATION_APPROVED",
  TOURNAMENT_REGISTRATION_PAID = "TOURNAMENT_REGISTRATION_PAID",
  TOURNAMENT_COMPLETE = "TOURNAMENT_COMPLETE",
  TEST = "TEST",
  ACHIEVEMENT_COMPLETE = "ACHIEVEMENT_COMPLETE",
  MEMBER_CLAIM_RECEIVED = "MEMBER_CLAIM_RECEIVED",
  MEMBER_CLAIM_APPROVED = "MEMBER_CLAIM_APPROVED",
  MEMBER_CLAIM_REJECTED = "MEMBER_CLAIM_REJECTED",
}

export interface Notification extends Record {
  _id: string;
  type: NotificationType;
  user?: string | User;
  match?: string | Match;
  board?: string | Board;
  member?: string | Member;
  tournament?: string | Event;
  creator?: string | User;
  read?: boolean;
  achievement?: string;
}
