import { MantineNumberSize, MediaQuery } from "@mantine/core";
import { ReactNode } from "react";

const Hider = ({
  children,
  largerThan,
  smallerThan,
  disabled,
}: {
  children: ReactNode;
  largerThan?: MantineNumberSize;
  smallerThan?: MantineNumberSize;
  disabled?: boolean;
}) => (
  <MediaQuery
    styles={{ display: disabled ? undefined : "none" }}
    largerThan={largerThan}
    smallerThan={smallerThan}
  >
    {children}
  </MediaQuery>
);
export default Hider;
