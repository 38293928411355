import { AppShell, Box, CSSObject, MantineTheme } from "@mantine/core";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Navbar from "./Navbar";
import { useAppStore } from "../../common/stores/app.store";
interface Props {
  children: ReactNode;
}
const AppShellComp = ({ children }: Props) => {
  const router = useRouter();
  const hasNav =
    router.pathname.includes("/boards/") || router.pathname.includes("/admin");
  const padding = hasNav ? "md" : 0;
  const isPwa = useAppStore((s) => s.isPwa);
  const screenshot = router.asPath.indexOf("/screenshots") === 0;
  return (
    <AppShell
      padding={padding}
      navbar={screenshot ? <></> : <Navbar />}
      header={screenshot ? <></> : <Header />}
      footer={screenshot ? <></> : <Footer />}
      styles={(theme: MantineTheme) => ({
        main: {
          WebkitTouchCallout: isPwa ? "none" : "auto",
          WebkitUserSelect: isPwa ? "none" : "auto",
          WebkitTapHighlightColor: isPwa ? "transparent" : "auto",
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        } as CSSObject,
      })}
      fixed
      navbarOffsetBreakpoint="xs"
    >
      <Box style={{ paddingBottom: 90 }}>{children}</Box>
    </AppShell>
  );
};
export default AppShellComp;
