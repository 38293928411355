import Head from "next/head";
import { useRouter } from "next/router";

const PageTitle = ({
  title,
  description,
  image,
}: {
  title: string;
  description?: string;
  image?: string;
}) => {
  // console.log("image :", image);
  image = image || "https://scoreboarder.net/assets/images/ogimage.png";
  const router = useRouter();
  const path = router.asPath;
  // const url =
  //   typeof window !== "undefined" ? window?.location?.href : router?.asPath;
  // console.log("url :", url);
  const host = process.env.NEXT_PUBLIC_SITE_URL || "https://scoreboarder.net";
  const url = host + path;
  // console.log('url :', url);
  return (
    <Head>
      <title>{title}</title>
      <meta property="og:title" content={title} key="title" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:url" content={url} />
      <meta property="og:url" content={url} />
      {description && (
        <>
          <meta name="description" content={description} />
          <meta
            property="og:description"
            content={description}
            key="description"
          />
          <meta name="twitter:description" content={description} />
        </>
      )}
      {image && (
        <>
          <meta name="image" content={image} />
          <meta property="og:image" content={image} key="image" />
          <meta name="twitter:image" content={image} />
        </>
      )}
    </Head>
  );
};
export default PageTitle;
