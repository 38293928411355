import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { useEffect } from "react";
import {
  getBoard,
  getMemberById,
  listMatches,
  listMembers,
  MAX_PAGE_SIZE,
} from "../api";
import { Match } from "../matches/match.schema";
import { Member } from "../members/member.schema";
import { useAuthStore } from "../stores/auth.store";
import { Board } from "./board.schema";
interface BoardProps {
  isLoading: boolean;
  isError: boolean;
  board?: Board;
}
// type Options = Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
export function useBoard(initBoard?: Board): BoardProps {
  const router = useRouter();
  const { boardShortLink } = router.query;
  // if (!boardShortLink && shortLink) boardShortLink = shortLink;
  const options: UseQueryOptions = {
    // staleTime: FIVE_MINUTES,
    enabled: !!boardShortLink,
  };
  if (initBoard) options.initialData = initBoard;
  const { isLoading, isError, data } = useQuery(
    ["board", boardShortLink],
    () => (boardShortLink ? getBoard(boardShortLink?.toString()) : null),
    options as any
  );
  const board = data as Board;
  return { isError, isLoading, board };
}
interface MembersProps {
  isLoading: boolean;
  isError: boolean;
  members?: Member[];
}
export function useMembers(
  boardId?: string,
  initMembers?: Member[]
): MembersProps {
  const options: UseQueryOptions = {
    // staleTime: FIVE_MINUTES,
  };
  if (initMembers) options.initialData = initMembers;
  const { board } = useBoard();
  boardId = boardId || board?._id;
  const { isLoading, isError, data } = useQuery(
    ["members", boardId],
    () =>
      boardId
        ? listMembers({ params: { board: boardId }, limit: MAX_PAGE_SIZE })
        : null,
    options as any
  );
  // console.log("isLoading, isError, data :", isLoading, isError, data);
  const members = data?.records as Member[];
  // console.log("in board hook members :", members);
  return { isError, isLoading, members };
}
interface MemberProps {
  isLoading: boolean;
  isError: boolean;
  member?: Member;
}
export function useMemberById(memberOrId?: string | Member): MemberProps {
  const options: UseQueryOptions = {
    // staleTime: FIVE_MINUTES,
  };
  if (memberOrId && (memberOrId as Member)?._id)
    options.initialData = memberOrId;
  // const { board } = useBoard();
  const memberId =
    (memberOrId && (memberOrId as Member)._id) || (memberOrId as string);
  options.enabled = memberId !== undefined;
  const { isLoading, isError, data } = useQuery(
    ["member", memberId],
    () => (memberId ? getMemberById(memberId) : null),
    options as any
  );
  // console.log("isLoading, isError, data :", isLoading, isError, data);
  const member = data as Member;
  // console.log("in board hook members :", members);
  return { isError, isLoading, member };
}
interface UseMatchesProps {
  boardId?: string;
  eventId?: string;
  options?: UseQueryOptions;
}
interface UseMatchesRes {
  isLoading: boolean;
  isError: boolean;
  matches?: Match[];
}
export function useMatches({
  boardId,
  eventId,
  options,
}: UseMatchesProps): UseMatchesRes {
  // const options: UseQueryOptions = {
  //   ...options
  //   staleTime: FIVE_MINUTES,
  // };
  const { board } = useBoard();
  boardId = boardId || board?._id;
  const { isLoading, isError, data } = useQuery(
    ["matches", boardId, eventId],
    () =>
      listMatches({
        params: { board: boardId, event: eventId },
        limit: MAX_PAGE_SIZE,
      }),
    options as any
  );
  // console.log("isLoading, isError, data :", isLoading, isError, data);
  const matches = data?.records as Match[];
  // console.log("in board hook members :", members);
  return { isError, isLoading, matches };
}
export function useRequireBoardAdmin() {
  const { board } = useBoard();
  const isAdmin = useIsBoardAdmin();
  const router = useRouter();
  useEffect(() => {
    if (!isAdmin && board) {
      // console.log("not board admin, redirect to root");
      router.replace("/");
    }
  }, [isAdmin, board, router]);
}
export function useIsBoardAdmin(): boolean {
  const { board } = useBoard();
  const user = useAuthStore((state) => state.user);
  if (!board || !user) return false;
  if (user?.admin) return true;
  return (board.admins as string[]).includes(user._id);
}

export function useIsBoardReferee(): boolean {
  const { board } = useBoard();
  const user = useAuthStore((state) => state.user);
  if (!board || !user) return false;
  if (user?.admin) return true;
  const isAdmin = (board.admins as string[]).includes(user._id);
  if (isAdmin) return true;
  return (board.referees as string[]).includes(user._id);
}

export function useSelfMember(): Member | undefined {
  const { members } = useMembers();
  const user = useAuthStore((state) => state.user);
  return members?.find((m) => m.user && m.user === user?._id);
}
export function useIsBoardMember(): boolean {
  const { board } = useBoard();
  const { members } = useMembers();
  const user = useAuthStore((state) => state.user);
  if (!board || !user) return false;
  if (user?.admin) return true;
  return members?.find((m) => m.user === user._id) !== undefined;
}
