import {
  IconAward,
  IconCalendarEvent,
  IconCoin,
  IconGraph,
  IconListNumbers,
  IconUser,
  IconUsers,
} from "@tabler/icons-react";
import { useTranslation } from "next-i18next";
import { Swords } from "../../common/icons/swords";
import NavLinks from "./NavLinks";
const ICON_SIZE = 20;

export function AdminLinks() {
  const { t } = useTranslation();
  const data = [
    {
      icon: <IconUsers size={ICON_SIZE} />,
      color: "red",
      label: t("users"),
      href: "",
      className: "users",
    },
    {
      icon: <IconListNumbers size={ICON_SIZE} />,
      color: "blue",
      label: t("boards"),
      href: "/boards",
      className: "leaderboard",
    },
    {
      icon: <Swords size={ICON_SIZE} />,
      color: "teal",
      label: t("matches"),
      href: "/matches",
      className: "matches",
    },
    {
      icon: <IconUser size={ICON_SIZE} />,
      color: "violet",
      label: t("members"),
      href: "/members",
      className: "members",
    },
    {
      icon: <IconCalendarEvent size={ICON_SIZE} />,
      color: "yellow",
      label: t("events"),
      href: "/events",
      className: "events",
    },
    {
      icon: <IconCoin size={ICON_SIZE} />,
      color: "green",
      label: t("payments"),
      href: "/payments",
      className: "payments",
    },
    {
      icon: <IconAward size={ICON_SIZE} />,
      color: "green",
      label: t("achievements"),
      href: "/achievements",
      className: "achievements",
    },
    {
      icon: <IconGraph size={ICON_SIZE} />,
      color: "green",
      label: t("stats"),
      href: "/stats",
      className: "stats",
    },
  ];
  return <NavLinks base="/admin" data={data} />;
}
