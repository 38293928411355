import { Anchor, MantineNumberSize } from "@mantine/core";
import Link from "next/link";
import { Board } from "../../common/boards/board.schema";
import { getBoardLink } from "../../common/util";

const BoardLink = ({
  board,
  size,
}: {
  board: Board;
  size?: MantineNumberSize;
}) => {
  return (
    <Anchor
      component={Link}
      href={getBoardLink(board)}
      size={size}
      sx={(theme) => ({
        color: theme.colorScheme === "dark" ? "white" : "black",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: theme.fontSizes.md,
        "@media (max-width: 755px)": {
          fontSize: theme.fontSizes.sm,
        },
        "@media (max-width: 576px)": {
          fontSize: theme.fontSizes.xs,
        },
        "@media (max-width: 400px)": {
          maxWidth: 100,
        },
        "@media (max-width: 300px)": {
          maxWidth: 80,
        },
      })}
    >
      {board.name}
    </Anchor>
  );
};
export default BoardLink;
