import create from "zustand";
import { persist } from "zustand/middleware";
import { Sport } from "../sports";
export const APP_STORE = "app-store";
export interface AppState {
  setIsPWA: (isPwa: boolean) => void;
  isPwa: boolean;
  setDefaultSport: (sport: Sport) => void;
  defaultSport: Sport;
  setIsPWAChecked: (isPwaChecked: boolean) => void;
  isPwaChecked: boolean;
  savingEventMatch?: boolean;
  setSavingEventMatch: (saving: boolean) => void;
  error?: Error;
  setError: (error: Error) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  toggleOpen: () => void;
  dark: boolean;
  setDark: (open: boolean) => void;
  toggleDark: () => void;
  pushPermission: NotificationPermission;
  setPushPermission: (permission: NotificationPermission) => void;
  dismissedPush: boolean;
  setDismissedPush: (open: boolean) => void;
}
const defaultDark =
  typeof window !== "undefined" &&
  window?.matchMedia &&
  window?.matchMedia("(prefers-color-scheme: dark)").matches;
export const useAppStore = create<AppState>()(
  persist(
    (set) => ({
      isPwa: false,
      setIsPWA: (isPwa: boolean) => set(() => ({ isPwa })),
      isPwaChecked: false,
      setIsPWAChecked: (isPwaChecked: boolean) => set(() => ({ isPwaChecked })),
      open: false,
      setOpen: (open: boolean) => set(() => ({ open })),
      toggleOpen: () => set((state) => ({ open: !state.open })),
      dark: defaultDark,
      setDark: (dark: boolean) => set(() => ({ dark })),
      dismissedPush: false,
      setDismissedPush: (dismissedPush: boolean) =>
        set(() => ({ dismissedPush })),
      toggleDark: () => set((state) => ({ dark: !state.dark })),
      setError: (error: Error) => set(() => ({ error })),
      savingEventMatch: false,
      setSavingEventMatch: (savingEventMatch: boolean) =>
        set(() => ({ savingEventMatch })),
      pushPermission: "default",
      setPushPermission: (pushPermission: NotificationPermission) =>
        set(() => ({ pushPermission })),
      defaultSport: Sport.FENCING,
      setDefaultSport: (defaultSport: Sport) => set(() => ({ defaultSport })),
    }),
    {
      name: APP_STORE,
      partialize: (state) => ({
        dark: state.dark,
        dismissedPush: state.dismissedPush,
      }),
    }
  )
);
