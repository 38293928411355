import {
  createStyles,
  Container,
  Group,
  Anchor,
  ActionIcon,
  Footer,
} from "@mantine/core";
import { IconSun, IconMoon } from "@tabler/icons-react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { isAndroid } from "react-device-detect";
import { useTranslation } from "next-i18next";
import { useAppStore } from "../../common/stores/app.store";
import { useAuthStore } from "../../common/stores/auth.store";
import { PLAY_STORE_LINK } from "../../common/util";
import Hider from "../common/Hider";
import { AdminLinks } from "./AdminLinks";
import Logo from "./Logo";
import { MainLinks } from "./MainLinks";

const useStyles = createStyles((theme) => ({
  footer: {
    // marginTop: 120,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,

    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column",
    },
  },

  links: {
    [theme.fn.smallerThan("xs")]: {
      marginTop: theme.spacing.md,
    },
  },
}));

export default function FooterSimple() {
  const [hasToken, setHasToken] = useState(false);
  const token = useAuthStore((state) => state.token);
  const router = useRouter();
  useEffect(() => {
    setHasToken(token !== null);
  }, [token]);
  const toggleDark = useAppStore((s) => s.toggleDark);
  const dark = useAppStore((s) => s.dark);
  const [displayDark, setDisplayDark] = useState(false);
  useEffect(() => setDisplayDark(dark), [dark]);
  const isPwa = useAppStore((s) => s.isPwa);
  const { t } = useTranslation(["common"]);
  const { classes } = useStyles();
  const links: { label: string; link: string; target?: string }[] = [
    { label: t("pricing"), link: "/pricing" },
    {
      label: t("support"),
      link: "/support",
    },
    { label: t("privacy"), link: "/privacy" },
    { label: t("terms"), link: "/terms" },
  ];
  if (!isPwa)
    links.push({
      label: t("app"),
      link: isAndroid ? PLAY_STORE_LINK : "/app",
      target: isAndroid ? "_blank" : "_self",
    });
  const boardPage = router.asPath.indexOf("/boards") === 0;
  const adminPage = router.asPath.indexOf("/admin") === 0;
  const showNavLinks = boardPage || adminPage;
  if (hasToken || showNavLinks)
    return (
      <Hider largerThan="xs">
        <Footer height="xl" fixed>
          <Group
            grow
            spacing={0}
            mb={5}
            position="apart"
            px="md"
            pt="sm"
            pb="xl"
          >
            {boardPage && <MainLinks />}
            {adminPage && <AdminLinks />}
          </Group>
        </Footer>
      </Hider>
    );
  const items = links.map((link) => (
    <Anchor
      component={Link}
      color="dimmed"
      size="sm"
      target={link.target}
      key={link.label}
      href={link.link}
    >
      {link.label}
    </Anchor>
  ));

  return (
    <div className={classes.footer}>
      <Container className={classes.inner}>
        <Logo />
        <Group className={classes.links} position="center">
          {items}
          <ActionIcon aria-label="dark mode" onClick={toggleDark}>
            {displayDark && <IconSun size={18} />}
            {!displayDark && <IconMoon size={18} />}
          </ActionIcon>
        </Group>
      </Container>
    </div>
  );
}
