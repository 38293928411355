import { useTheme } from "@emotion/react";
import { Box, Group, Stack, Text, UnstyledButton } from "@mantine/core";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import Hider from "../common/Hider";
interface LinkProps {
  icon: React.ReactNode;
  label: string;
  href: string;
  className?: string;
}
interface LinkItemProps extends LinkProps {
  base: string;
}
function LinkItem({ icon, label, href, className, base }: LinkItemProps) {
  // console.log("href :", href);
  const { t } = useTranslation();
  label = t(label);
  const theme = useTheme();
  const url = `${base}${href}`;
  // console.log("url :", url);
  const router = useRouter();
  let current = router.asPath.indexOf(url) === 0;
  if (href === "") current = router.asPath === url;
  // console.log("router.asPath :", router.asPath);
  // console.log("current :", current);
  let iconColor =
    theme.colorScheme === "dark" ? theme.colors.blue[5] : theme.colors.gray[9];
  if (current)
    iconColor =
      theme.colorScheme === "dark"
        ? theme.colors.gray[3]
        : theme.colors.blue[5];
  return (
    <Box>
      <UnstyledButton
        component={Link}
        href={url}
        className={className}
        p={0}
        aria-label={label}
        sx={(theme) => ({
          display: "block",
          width: "100%",
          padding: theme.spacing.xs,
          borderRadius: theme.radius.sm,
          color:
            theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
        })}
      >
        <Hider largerThan="md">
          <Stack align="center" spacing={0}>
            <Text color={iconColor}>{icon}</Text>
            <Text size={9}>{label}</Text>
          </Stack>
        </Hider>
        <Hider smallerThan="md">
          <Group pt={4} pl={10}>
            <Text color={iconColor}>{icon}</Text>
            <Text pb={6} size="sm">
              {label}
            </Text>
          </Group>
        </Hider>
      </UnstyledButton>
    </Box>
  );
}

function NavLinks({ base, data }: { base: string; data: LinkProps[] }) {
  const displayData = [...data];
  const links = displayData.map((link) => (
    <LinkItem {...link} key={link.label} base={base} />
  ));
  return <>{links}</>;
}
export default NavLinks;
