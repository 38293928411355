import { Record } from "../record.schema";
export const MIN_PASSWORD_LENGTH = 6;
export const MIN_NAME_LENGTH = 2;
export enum PlanType {
  FREE = "FREE",
  TEAM = "TEAM",
  CLUB = "CLUB",
  ORG = "ORG",
}
export enum StripeSubscriptionStatus {
  incomplete = "incomplete",
  incomplete_expired = "incomplete_expired",
  trialing = "trialing",
  active = "active",
  past_due = "past_due",
  canceled = "canceled",
  unpaid = "unpaid",
}
export interface User extends Record {
  _id: string;
  lastActive: Date;
  lastLogin: Date;
  loginCount: number;
  admin: boolean;
  unsubscribed: boolean;
  name: string;
  email: string;
  image: string;
  imageKey: string;
  imageUpdatedAt: Date;
  banned?: boolean;
  notifyEmail: boolean;
  notifyPush: boolean;
  notifyAddedToBoardAdmin: boolean;
  notifyAddedToBoard: boolean;
  notifyMatchAdded: boolean;
  lastName: string;
  club?: string;
  usfaMemberNumber: string;
  pushEndpoint?: string;
}
